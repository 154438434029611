/* Styles for modal window */

.info {
  color: #a0a000;
}

.error {
  color: red;
}

.header {
  margin-top: 1em;
}

.status {
  margin-top: 2em;
  font-weight: bold;
  font-size: large;
}

.alert.card.card-body {
  display: none;
  margin-top: 2em;
}

/* Set header in center */
#updateErrorHeader {
  margin-left: 2em;
}

.recipients {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin: 2rem auto;
  align-items: flex-start;
  gap: 5px;
}

.recipients-btn {
  font-size: 1.2rem;
  line-height: 1.2;
}

.apiErrorTable {
  font-size: 1.15rem;
  margin-bottom: 15px;
  width: 100%;
}

.apiErrorTableHeader {
  font-weight: bold;
  border: 1px solid grey;
  padding: 2px 5px;
}

.form-group {
  margin-bottom: 1rem;
}
