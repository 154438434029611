/*
  Style for Tabulator table and related things.
  See https://tabulator.info/docs/6.2/style#css to get more information about available classes.
*/

.tabulator-table, .tabulator-header {
  font-size: 1rem;
}

.tabulator.table .tabulator-row {
  padding: 0;
}

.tabulator-cell {
  text-align: center;
}

.tabulator-cell.editable {
  color: var(--bs-blue);
  cursor: pointer;
}

.tabulator-cell.editable:hover {
  text-decoration: underline;
}

.tabulator-cell.clickable {
  cursor: pointer;
}

.tabulator-cell.clickable:hover {
  color: var(--bs-blue)
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}


/* Styles for custom editor nrsMultiSelect */

.nrs-multi-select-wrapper {
  position: relative;
}

.nrs-multi-select-wrapper > input {
  padding-right: 2rem !important;
}

.nrs-multi-select-wrapper > button {
  align-items: center;
  background-color: transparent;
  border: none;
  display: flex;
  height: 1rem;
  justify-content: center;
  padding: 0;
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1rem;
}

/* For empty options */
.tabulator-edit-select-list-item {
  min-height: 2rem;
}

.tabulator .tabulator-footer{
  /* Use @important to override tabulator default styles. */
  background-color: #fff !important;
  font-size: 1rem;
  display: flex;
  justify-content: flex-end;
}

.tabulator-footer-contents {
  /* uUe @important to override tabulator default styles. */
  align-items: baseline !important;
}

.tabulator-col-content {
  padding: 0.75rem !important;
}

.tabulator-edit-select-list {
  position: absolute;
  display: inline-block;
  box-sizing: border-box;
  max-height: 200px;
  background: #fff;
  border: 1px solid #dee2e6;
  font-size: 1rem;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 10000;
}

.tabulator-edit-select-list-item {
  font-size: 1rem;
}

.tabulator-edit-select-list .tabulator-edit-select-list-item.active{
  color: #fff;
  background: #1D68CD;
}

.tabulator-edit-select-list .tabulator-edit-select-list-item:hover {
  cursor: pointer;
  color: #fff;
  background: #1D68CD;
}
