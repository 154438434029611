/* Styles for bootstrap table Shipments */

.table-nrs {
  width: 100%;
}

/* Import bootstrap button */
button[name="btnImport"] {
  background: green;
  width: 10em;
}

button[name="btnImport"] .fa-upload::after {
  margin-left: 0.5em;
  font-family: Arial, serif;
  font-weight: bolder;
  content: 'Import data';
}

/* Clear bootstrap button */
button[name="btnClear"] {
  background: #ac0000;
  width: 10em;
}

button[name="btnClear"] .fa-trash::after {
  margin-left: 0.5em;
  font-family: Arial, serif;
  font-weight: bolder;
  content: 'Clear DB';
}

.btn:disabled {
  opacity: 30;
}

/* Styles for UPRR ul table */
ul {
  padding-inline-start: 1em;
  list-style-type: none;
}

/* Drowdown styles */
.columns-list {
  max-height: 200px;
  overflow: auto;
}

.columns-list label {
  width: 100%;
}

.columns-list input[type=checkbox] {
  margin-right: 10px;
}
