.print-shipments {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  border: 1px solid #000;
}

.cell {
  border: 1px solid #000;
  text-align: left;
  padding: 3px;
  height: 10mm;
}

.grey-text {
  color: #bbb;
}

.bordered-bottom {
  border-bottom: 5px solid #000;
}

.page {
  width: 210mm;
  min-height: 297mm;
  padding: 20mm;
  margin: 10mm auto;
  background: white;
}

.print-form-title {
  text-align: center;
}

.print-form-title h4 {
  font-size: 1.2rem;
}

.print-form-title p,
.print-form-data p,
.print-form-contacts p {
  margin-bottom: 0;
}

.print-form-data {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.print-form-data > div {
  display: flex;
}

.print-form-data > div p {
  width: 50%;
}

@page {
  size: a4;
  margin: 0;
}

@media print {
  html {
    width: 210mm;
    height: 297mm;
  }

  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
}
